import { Auth } from "aws-amplify";
import { route } from "preact-router";
import { useState, useRef } from "preact/hooks";
import { useAppStore } from "../store";
import { Captcha } from '../captcha'
import { Text, Localizer } from "preact-i18n";
import { Link } from "preact-router/match";
import styles from "../routes/style.scss";

const ForgotPassword = () => {
	const isCaptchaVerified = useAppStore((state) => state.isCaptchaVerified);
	const username = useAppStore((state) => state.username);
	const setUsername = useAppStore((state) => state.setUsername);
	const setUsernameObfuscated = useAppStore(
		(state) => state.setUsernameObfuscated,
	);
	const modalRef = useRef(null);
	const handleClose = () => {
		modalRef.current.style.display = "none";
	};
	const [error, setError] = useState("");
	const onSubmit = async (e) => {
		e.preventDefault();
		setError("");
		try {
			const res = await Auth.forgotPassword(username);
			setUsernameObfuscated(res.CodeDeliveryDetails.Destination);
			route(`/confirm-forgot-password`, true);
		} catch (err) {
			setError(err.code);
		}
	};
	return (
		<>
			<div className={styles.login_modal} ref={modalRef}>
				<div className={styles.container}>
					<div className={styles.tabs}>
						<Link
							href={`/sign-in`}
							className={styles.tab + " " + styles.active}
						>
							<Text id="signUp.Log in">Log In</Text>
						</Link>
						<Link href={`/sign-up`} className={styles.tab}>
							<Text id="signUp.Sign up">Sign up</Text>
						</Link>
					</div>
					<div className={styles.content}>
						<div onClick={handleClose} className={styles.close}></div>
						<div className={styles.paragraph}>
							<Text id="forgotPassword.Enter your Email below and we will send a message to reset your password">
								Enter your Email below and we will send a message to reset your
								password
							</Text>
						</div>
						<div
							className={styles.paragraph}
							class="cognitoFormError forgotPasswordFormError"
						>
							<Text id={`error.${error}`}>{error}</Text>
						</div>
						<form onSubmit={onSubmit}>
							<div>
								<input
									id="signInFormUsername"
									name="username"
									type="text"
									autocapitalize="none"
									required="true"
									value={username}
									placeholder="E-Mail Adresse"
									onInput={(e) => {
										setUsername(e.target.value.toLowerCase());
									}}
								/>
							</div>
							<Captcha />
							<button
								name="reset_my_password"
								type="submit"
								className={styles.navbutton}
								disabled={!isCaptchaVerified}
							>
								<Text id="forgotPassword.Reset my password">
									Reset my password
								</Text>
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
