import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports.json";

Amplify.configure(awsconfig);
import App from "./components/app";
import register from "preact-custom-element";

// Fallback if web components are not viable
// if (typeof window !== "undefined") {
//     render(<App />, document.getElementById("cognitoui"));
// }

register(App, "cognito-ui", ["autoUseMultipass", "multipassPostSignInUrl"], {
	shadow: false,
});
