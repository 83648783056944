import { useRef, useEffect } from "preact/hooks";
import { useAppStore } from "./store";
import awsconfig from "./aws-exports.json";

export const Captcha = () => {
	const ref = useRef(null);
	const isCaptchaVerified = useAppStore((state) => state.isCaptchaVerified);
	const setIsCaptchaVerified = useAppStore(
		(state) => state.setIsCaptchaVerified,
	);
	useEffect(() => {
		!isCaptchaVerified && window.AwsWafCaptcha.renderCaptcha(ref.current, {
			onSuccess: () => {
				setIsCaptchaVerified(true)
			},
      defaultLocale: "de-DE",
      skipTitle: true,
			apiKey: awsconfig.aws_waf_captcha_key,
		})
	}, [isCaptchaVerified])
	return (<div ref={ref} />)
}

