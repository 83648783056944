import create from "zustand";

export const useAppStore = create((set) => ({
	user: null,
	username: "",
	usernameObfuscated: "",
	isCaptchaVerified: false,
	setUser: (user) => set((_) => ({ user })),
	setUsername: (username) => set((_) => ({ username })),
	setUsernameObfuscated: (usernameObfuscated) =>
		set((_) => ({ usernameObfuscated })),
	autoUseMultipass: false,
	setAutoUseMultipass: (autoUseMultipass) => set((_) => ({ autoUseMultipass })),
	multipassPostSignInUrl: "",
	setMultipassPostSignInUrl: (multipassPostSignInUrl) =>
		set((_) => ({ multipassPostSignInUrl })),
	setIsCaptchaVerified: (isCaptchaVerified) => set((_) => ({ isCaptchaVerified }))
}));
