import { Auth } from "aws-amplify";
import { route } from "preact-router";
import { useState, useEffect, useRef } from "preact/hooks";
import { useAppStore } from "../store";
import { Text } from "preact-i18n";
import { Link } from "preact-router/match";
import styles from "../routes/style.scss";

const Confirm = () => {
	const username = useAppStore((state) => state.username);
	const usernameObfuscated = useAppStore((state) => state.usernameObfuscated);
	const [error, setError] = useState("");
	const [code, setCode] = useState("");
	const modalRef = useRef(null);

	useEffect(() => {
		if (!username) {
			route(`/sign-in`, true);
		}
	}, [username]);

	const resendCode = async (e) => {
		e.preventDefault();
		setError("");
		try {
			await Auth.resendSignUp(username);
		} catch (err) {
			setError(err.code);
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setError("");
		try {
			await Auth.confirmSignUp(username, code);
			route(`/sign-in`, true);
		} catch (err) {
			setError(err.code);
		}
	};

	return (
		<div className={styles.login_modal} ref={modalRef}>
			<div className={styles.container}>
				<div className={styles.tabs}>
					<Link href={`/sign-in`} className={styles.tab}>
						<Text id="signUp.Log in">Log In</Text>
					</Link>
					<Link href={`/sign-up`} className={styles.tab + " " + styles.active}>
						<Text id="signUp.Sign up">Sign up</Text>
					</Link>
				</div>
				<div className={styles.content}>
					<form onSubmit={onSubmit}>
						<div className={styles.paragraph}>
							<Text id="confirm.Confirm your account">
								Confirm your account
							</Text>
						</div>
						<div className={styles.paragraph}>
							<Text id="confirm.We have sent a code by email to">
								We have sent a code by email to {usernameObfuscated}.
							</Text>
						</div>
						<div
							className={styles.paragraph}
							class="cognitoFormError confirmFormError"
						>
							<Text id={`error.${error}`}>{error}</Text>
						</div>
						<div>
							<label for="verification_code">
								<Text id="confirm.Verification code">Verification code</Text>
							</label>
							<input
								id="verification_code"
								type="password"
								name="code"
								required="true"
								value={code}
								onInput={(e) => setCode(e.target.value)}
							/>
						</div>
						<div>
							<button name="confirm" type="submit" className={styles.navbutton}>
								<Text id="confirm.Confirm account">Confirm account</Text>
							</button>
						</div>
						<div>
							<Text id="confirm.Didn't receive a code?">
								Didn't receive a code?
							</Text>{" "}
							<a href="#" onClick={resendCode}>
								<Text id="confirm.Send a new code">Send a new code</Text>
							</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Confirm;
