import { Component } from "preact";
import { Router } from "preact-router";
import { createHashHistory } from "history";
import SignIn from "./sign-in";
import SignUp from "./sign-up";
import ForgotPassword from "./forgot-password";
import Confirm from "./confirm";
import ConfirmForgotPassword from "./confirm-forgot-password";
import NewPasswordRequired from "./new-password-required";
import { IntlProvider } from "preact-i18n";
import langDe from "../lang.de.json";
import { useAppStore } from "../store";

class App extends Component {
	render({
		autousemultipass: autoUseMultipass,
		multipasspostsigninurl: multipassPostSignInUrl,
	}) {
		const setMultipassPostSignInUrl = useAppStore(
			(state) => state.setMultipassPostSignInUrl,
		);
		setMultipassPostSignInUrl(multipassPostSignInUrl);
		const setAutoUseMultipass = useAppStore(
			(state) => state.setAutoUseMultipass,
		);
		setAutoUseMultipass(autoUseMultipass === "true" ? true : false);
		return (
			<IntlProvider definition={langDe}>
				<Router history={createHashHistory()}>
					<SignIn path="/sign-in" />
					<SignUp path="/sign-up" />
					<ForgotPassword path="/forgot-password" />
					<ConfirmForgotPassword path="/confirm-forgot-password" />
					<NewPasswordRequired path="/new-password-required" />
					<Confirm path="/confirm" />
				</Router>
			</IntlProvider>
		);
	}
}

export default App;
