import { Auth } from "aws-amplify";
import { route } from "preact-router";
import { Link } from "preact-router/match";
import { useState, useRef } from "preact/hooks";
import { Captcha } from '../captcha'
import { useAppStore } from "../store";
import { Text, Localizer } from "preact-i18n";
import styles from "../routes/style.scss";

const SignUp = () => {
	const isCaptchaVerified = useAppStore((state) => state.isCaptchaVerified);
	const username = useAppStore((state) => state.username);
	const setUsername = useAppStore((state) => state.setUsername);
	const setUsernameObfuscated = useAppStore(
		(state) => state.setUsernameObfuscated,
	);
	const modalRef = useRef(null);
	const handleClose = () => {
		modalRef.current.style.display = "none";
	};
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [error, setError] = useState("");
	const onSubmit = async (e) => {
		e.preventDefault();
		setError("");
		try {
			const res = await Auth.signUp({
				username,
				password,
				attributes: {
					email: username,
					given_name: firstName,
					family_name: lastName,
				},
			});
			setUsernameObfuscated(res.codeDeliveryDetails.Destination);
			route(`/confirm`, true);
		} catch (err) {
			setError(err.code);
		}
	};
	return (
		<>
			<div className={styles.login_modal} ref={modalRef}>
				<div className={styles.container}>
					<div className={styles.tabs}>
						<Link href={`/sign-in`} className={styles.tab}>
							<Text id="signUp.Log in">Log In</Text>
						</Link>
						<Link
							href={`/sign-up`}
							className={styles.tab + " " + styles.active}
						>
							<Text id="signUp.Sign up">Sign up</Text>
						</Link>
					</div>
					<div className={styles.content}>
						<div onClick={handleClose} className={styles.close}></div>
						<div className={styles.heading}>
							<Text id="signUp.Sign up with a new account">
								Sign up with a new account
							</Text>
						</div>
						<form onSubmit={onSubmit}>
							<div className={styles.wrapper}>
								<div className={styles.cola}>
									<div>
										<Localizer>
											<input
												id="signInFormFirstName"
												name="firstName"
												type="text"
												autocapitalize="none"
												required="true"
												value={firstName}
												placeholder={
													<Text id="signUp.First name">FirstName</Text>
												}
												onInput={(e) => setFirstName(e.target.value)}
											/>
										</Localizer>
									</div>
									<div>
										<Localizer>
											<input
												id="signInFormLastName"
												name="lastName"
												type="text"
												autocapitalize="none"
												required="true"
												value={lastName}
												placeholder={
													<Text id="signUp.Last name">LastName</Text>
												}
												onInput={(e) => setLastName(e.target.value)}
											/>
										</Localizer>
									</div>

									<div
										class="cognitoFormError signInFormError"
										style="color: red;font-size: 12px; margin: 13px 0;"
									>
										<Text id={`error.${error}`}>{error}</Text>
									</div>
								</div>
								<div className={styles.colb + " " + styles.signup}>
									<div>
										<Localizer>
											<input
												id="signInFormUsername"
												name="username"
												type="text"
												autocapitalize="none"
												required="true"
												value={username}
												placeholder={<Text id="signUp.Email">Email</Text>}
												onInput={(e) => {
													setUsername(e.target.value.toLowerCase());
												}}
											/>
										</Localizer>
									</div>
									<div>
										<Localizer>
											<input
												id="signInFormPassword"
												name="password"
												type="password"
												required="true"
												value={password}
												placeholder={<Text id="signUp.Password">Password</Text>}
												onInput={(e) => setPassword(e.target.value)}
											/>
										</Localizer>
									</div>
								</div>
								</div>
								<Captcha />
								<div className={styles.wrapper}>
								<div className={styles.cola}></div>
								<div className={styles.colb + " " + styles.signup}>
								<button
										type="submit"
										name="signUpButton"
										aria-label="submit"
										className={styles.navbutton}
										disabled={!isCaptchaVerified}
									>
										<Text id="signUp.Sign up button">Sign up</Text>
									</button>
								</div>

							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignUp;
