import { Auth } from "aws-amplify";
import { route } from "preact-router";
import { useState, useRef, useEffect } from "preact/hooks";
import { useAppStore } from "../store";
import { Text, Localizer } from "preact-i18n";
import styles from "../routes/style.scss";

const newPasswordRequired = () => {
	const user = useAppStore((state) => state.user);
	const [newPassword, setNewPassword] = useState("");
	const modalRef = useRef(null);
	const handleClose = () => {
		modalRef.current.style.display = "none";
	};
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [error, setError] = useState("");
	useEffect(() => {
		if (!user) {
			route(`/`, true);
		}
	}, [user]);
	const onSubmit = async (e) => {
		e.preventDefault();
		setError("");
		if (newPassword !== confirmNewPassword) {
			setError("Passwords don't match");
			return;
		}
		try {
			const res = await Auth.completeNewPassword(user, newPassword);
			route(`/sign-in`, true);
		} catch (err) {
			setError(err.message || err.code);
		}
	};
	return (
		<>
			<div className={styles.login_modal} ref={modalRef}>
				<div className={styles.container}>
					<div className={styles.content + " " + styles.rounded_corners}>
						<div onClick={handleClose} className={styles.close}></div>
						<form onSubmit={onSubmit}>
							<div
								className={styles.paragraph}
								class="cognitoFormError newPasswordRequiredFormError"
							>
								<Text id={`error.${error}`}>{error}</Text>
							</div>
							<div>
								<Localizer>
									<input
										id="new_password"
										type="password"
										name="newPassword"
										required="true"
										value={newPassword}
										placeholder={
											<Text id="newPasswordRequired.New Password">
												New Password
											</Text>
										}
										onInput={(e) => setNewPassword(e.target.value)}
									/>
								</Localizer>
							</div>
							<div>
								<Localizer>
									<input
										id="confirm_password"
										type="password"
										name="confirmNewPassword"
										required="true"
										value={confirmNewPassword}
										placeholder={
											<Text id="newPasswordRequired.Enter New Password Again">
												Enter New Password Again
											</Text>
										}
										onInput={(e) => setConfirmNewPassword(e.target.value)}
									/>
								</Localizer>
							</div>
							<button
								name="reset_password"
								type="submit"
								aria-label="submit"
								className={styles.navbutton}
							>
								<Text id="newPasswordRequired.Change Password">
									Change Password
								</Text>
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default newPasswordRequired;
