import { Auth } from "aws-amplify";
import { route } from "preact-router";
import { Link } from "preact-router/match";
import { useState, useRef, useEffect } from "preact/hooks";
import { useAppStore } from "../store";
import { Text, Localizer } from "preact-i18n";
import styles from "../routes/style.scss";

const SignIn = () => {
	const username = useAppStore((state) => state.username);
	const setUser = useAppStore((state) => state.setUser);
	const setUsername = useAppStore((state) => state.setUsername);
	const setUsernameObfuscated = useAppStore(
		(state) => state.setUsernameObfuscated,
	);
	const modalRef = useRef(null);
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const multipassPostSignInUrl = useAppStore(
		(state) => state.multipassPostSignInUrl,
	);
	const autoUseMultipass = useAppStore((state) => state.autoUseMultipass);
	const onSubmit = async (e) => {
		e.preventDefault();
		setError("");
		setIsLoading(true);
		try {
			const user = await Auth.signIn(username, password);
			if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
				setUser(user);
				route("/new-password-required", true);
			} else {
				setPassword("");
				setUsername("");
				handleClose();
				if (multipassPostSignInUrl && autoUseMultipass) {
					await useMultipass();
				}
			}
		} catch (err) {
			if (err.code === "UserNotConfirmedException") {
				setUsernameObfuscated(username);
				route(`/confirm`, true);
			}
			setError(err.code);
		}
		setTimeout(() => setIsLoading(false), 150);
	};
	const useMultipass = async () => {
		if (!multipassPostSignInUrl) return;
		const user = await Auth.currentSession();
		window.location.replace(
			`${multipassPostSignInUrl}?id_token=${user.idToken.jwtToken}`,
		);
	};
	useEffect(async () => {
		if (multipassPostSignInUrl && autoUseMultipass) {
			await useMultipass();
		}
	}, [multipassPostSignInUrl, autoUseMultipass]);
	const handleClose = () => {
		modalRef.current.style.display = "none";
	};

	return (
		<>
			<div className={styles.login_modal} ref={modalRef}>
				<div className={styles.container}>
					<div className={styles.tabs}>
						<Link
							href={`/sign-in`}
							className={styles.tab + " " + styles.active}
						>
							<Text id="signIn.Log in">Log In</Text>
						</Link>
						<Link href={`/sign-up`} className={styles.tab}>
							<Text id="signIn.Sign up">Sign up</Text>
						</Link>
					</div>
					<div className={styles.content}>
                        {isLoading ? (
                            <>
                                <div className={styles.loadingOverlay}></div>
                                <div className={styles.loadingOverlayImageContainer}>
                                    <div className={styles.loading}></div>
                                </div>
                            </>
                        ) : ''}
						<div onClick={handleClose} className={styles.close}></div>
                            <div className={styles.heading}>
                                <Text id="signIn.Header">Log In</Text>
                            </div>
                            <div className={styles.wrapper}>
                                <div className={styles.cola}>
                                    <form onSubmit={onSubmit}>
                                        <div>
                                            <Localizer>
                                                <input
                                                    id="signInFormUsername"
                                                    name="username"
                                                    type="text"
                                                    autocapitalize="none"
                                                    required="true"
                                                    value={username}
                                                    placeholder={<Text id="signIn.Email">Email</Text>}
                                                    onInput={(e) => {
                                                        setUsername(e.target.value.toLowerCase());
                                                    }}
                                                    style={error ? "background:#FBE3DF" : ""}
                                                />
                                            </Localizer>
                                        </div>
                                        <div>
                                            <Localizer>
                                                <input
                                                    id="signInFormPassword"
                                                    name="password"
                                                    type="password"
                                                    required="true"
                                                    value={password}
                                                    placeholder={
                                                        <Text id="signIn.Password">Password</Text>
                                                    }
                                                    onInput={(e) => setPassword(e.target.value)}
                                                    style={error ? "background:#FBE3DF" : ""}
                                                />
                                            </Localizer>
                                        </div>
                                        <Localizer>
                                            <input
                                                name="signInSubmitButton"
                                                type="submit"
                                                value={<Text id="signIn.Sign in">Sign in</Text>}
                                                aria-label="submit"
                                            />
                                        </Localizer>
                                    </form>
                                    <div>
                                        <Link href={`/forgot-password`}>
                                            <Text id="signIn.Forgot your password?">
                                                Forgot your password?
                                            </Text>
                                        </Link>
                                    </div>
                                    <div
                                        class="cognitoFormError signInFormError"
                                        style="color: red;font-size: 12px;margin: 13px 0;"
                                    >
                                        <Text id={`error.${error}`}>{error}</Text>
                                    </div>
                                </div>
                                <div className={styles.colb}>
                                    <h3 className={styles.title}>
                                        <Text id="signIn.Title">Title</Text>
                                    </h3>
                                    <span className={styles.description}>
                                        <Text id="signIn.Description">Description</Text>
                                    </span>
                                    <Link href={`/sign-up`} className={styles.navbutton}>
                                        <Text id="signIn.Button">Button</Text>
                                    </Link>
                                </div>
                            </div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignIn;
